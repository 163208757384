const convertTo24HourFormat = (time) => {
  const [hours, minutes, period] = time
    .match(/(\d+):(\d+)\s?(AM|PM)/i)
    .slice(1);
  let hours24 = parseInt(hours, 10);
  if (period.toUpperCase() === "PM" && hours24 !== 12) {
    hours24 += 12;
  } else if (period.toUpperCase() === "AM" && hours24 === 12) {
    hours24 = 0;
  }
  return `${hours24.toString().padStart(2, "0")}:${minutes}`;
};

const compareTimes = (time1, time2) => {
  const time1_24h = convertTo24HourFormat(time1);
  const time2_24h = convertTo24HourFormat(time2);
  if (time1_24h < time2_24h) {
    return -1;
  } else if (time1_24h > time2_24h) {
    return 1;
  } else {
    return 0;
  }
};
const findNearestStartTime = (timeSlots, inputTime) => {
  const inputTime_24h = convertTo24HourFormat(inputTime);

  // Convert all available time slots to 24-hour format
  const availableStartTimes = timeSlots.map((slot) => ({
    ...slot,
    startTime24h: convertTo24HourFormat(slot.time),
  }));

  // Sort available time slots by start time
  availableStartTimes.sort((a, b) =>
    a.startTime24h.localeCompare(b.startTime24h)
  );

  // Find the nearest available start time
  for (const slot of availableStartTimes) {
    if (slot.startTime24h >= inputTime_24h) {
      return slot.time; // Return in original 12-hour format
    }
  }

  return null; // No available slot found
};

const isAppointmentAvailable = (appointments, time, nextTime, compareTimes) => {
  if (!appointments || appointments.length === 0) return { allowed: true };

  for (const apmnt of appointments) {
    let x = 0;
    let y = 0;
    let over = 0;

    if (nextTime) {
      x = compareTimes(nextTime, apmnt.endTime);
      over = compareTimes(nextTime, apmnt.time);
    }
    if (time) {
      y = compareTimes(time, apmnt.time);
    }

    if (
      ((apmnt.time === time && apmnt.mrn) ||
        (apmnt.endTime === nextTime && apmnt.mrn) ||
        (apmnt.endTime === nextTime && apmnt.mrn.includes("Tmp")) ||
        (over > 0 && y < 0) ||
        (x === -1 && y === 1)) &&
      apmnt.mrn
    ) {
      return { allowed: false, appointment: apmnt, appointmentId: apmnt._id };
    }
  }

  return { allowed: true };
};

const generateTimeOptions = (selectedDoctor) => {
  const options = [];
  let startHour = 8;
  let endHour = 20;

  if (selectedDoctor === "Dr. Saranya") {
    startHour = 10;
    endHour = 17;
  }
  if (selectedDoctor === "Dr. Suraja") {
    startHour = 8;
    endHour = 22;
  }
  if (selectedDoctor === "Dr. Gokul") {
    startHour = 11;
    endHour = 20;
  }

  for (let hour = startHour; hour <= endHour; hour++) {
    // Start from 8 AM, end at 5 PM
    for (let minute = 0; minute < 60; minute += 15) {
      // Increment by 15 minutes
      const period = hour >= 12 ? "PM" : "AM";
      const formattedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour; // Convert 24-hour format to 12-hour format
      const formattedMinute = minute.toString().padStart(2, "0"); // Ensure minutes are always 2 digits
      const time = `${formattedHour}:${formattedMinute} ${period}`;
      if (hour === endHour && minute >= 15) break; // Stop at 5:00 PM
      options.push(time);
    }
  }

  return options;
};

const formatTime12Hour = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // Convert 0 and 12 to 12
  const formattedMinutes = minutes.toString().padStart(2, "0");
  console.log(`${formattedHours}:${formattedMinutes} ${period}`);
  return `${formattedHours}:${formattedMinutes} ${period}`;
};

export {
  generateTimeOptions,
  isAppointmentAvailable,
  compareTimes,
  convertTo24HourFormat,
  findNearestStartTime,
  formatTime12Hour,
};

const extractRange = (rangeString, sex, age) => {
  let ranges = [];
  let normalizedString = rangeString.toLowerCase().replace(/\s+/g, " "); // Normalize spaces
  console.log(normalizedString);

  // Handle 'up to' ranges (e.g., "Male up to 45 U/L")
  const upToRegex = new RegExp(
    `(${sex})?\\s*up to\\s*(\\d+\\.?\\d*)\\s*([a-zA-Z/]+)?`,
    "i"
  );
  const upToMatch = normalizedString.match(upToRegex);
  if (upToMatch) {
    const [, gender, maxVal, unit] = upToMatch;
    if (!gender || gender.toLowerCase() === sex.toLowerCase()) {
      return [{ start: 0, end: parseFloat(maxVal), unit: unit || null }];
    }
  }

  // Handle explicit sex-based numeric ranges (e.g., "Male 19 - 45 mg/dl")
  const sexBasedRegex = new RegExp(
    `(${sex})\\s*(\\d+\\.?\\d*)\\s*-\\s*(\\d+\\.?\\d*)\\s*([a-zA-Z/]+)?`,
    "i"
  );
  const sexMatch = normalizedString.match(sexBasedRegex);
  if (sexMatch) {
    ranges.push({
      start: parseFloat(sexMatch[2]),
      end: parseFloat(sexMatch[3]),
      unit: sexMatch[4] || null,
    });
  }

  // Extract general numeric ranges with units (e.g., "0.3 - 1.2 ng/ml")
  const rangeRegex = /(\d+(\.\d+)?)\s*-\s*(\d+(\.\d+)?)\s*([a-zA-Z/]+)?/g;
  let match;
  while ((match = rangeRegex.exec(normalizedString)) !== null) {
    ranges.push({
      start: parseFloat(match[1]),
      end: parseFloat(match[3]),
      unit: match[5] || null,
    });
  }

  // Extract age-dependent ranges (e.g., "0 - 15 (Below 50 Male) ng/ml")
  const ageBasedRegex = new RegExp(
    `(\\d+\\s*-\\s*\\d+)\\s*\\((below|above)?\\s*(\\d+)\\s*(${sex})?\\)\\s*([a-zA-Z/]+)?`,
    "gi"
  );
  let ageMatch;
  while ((ageMatch = ageBasedRegex.exec(normalizedString)) !== null) {
    const [fullMatch, rangePart, ageCondition, ageLimit, , unit] = ageMatch;
    const [start, end] = rangePart.split("-").map(Number);

    if (
      (ageCondition === "below" && age < parseInt(ageLimit)) ||
      (ageCondition === "above" && age >= parseInt(ageLimit))
    ) {
      ranges.push({ start, end, unit: unit || null });
    }
  }

  return ranges.length > 0 ? ranges : null;
};

export default extractRange;

//const extractRange = (rangeString, sex, age) => {
//  let ranges = [];
//  let normalizedString = rangeString.toLowerCase().replace(/\s+/g, " "); // Normalize spaces
//  console.log(normalizedString);
//  // Handle 'up to' ranges (e.g., "Male up to 45 U/L")
//  const upToRegex = new RegExp(`(${sex})?\\s*up to\\s*(\\d+\\.?\\d*)`, "i");
//  const upToMatch = normalizedString.match(upToRegex);
//  if (upToMatch) {
//    const [, gender, maxVal] = upToMatch;
//    if (!gender || gender.toLowerCase() === sex.toLowerCase()) {
//      return [{ start: 0, end: parseFloat(maxVal) }]; // Ensure the range is 0 to maxVal
//    }
//  }
//
//  // Handle explicit sex-based numeric ranges (e.g., "Male 19 - 45 mg/dl")
//  const sexBasedRegex = new RegExp(
//    `(${sex})\\s*(\\d+\\.?\\d*)\\s*-\\s*(\\d+\\.?\\d*)`,
//    "i"
//  );
//  const sexMatch = normalizedString.match(sexBasedRegex);
//  if (sexMatch) {
//    ranges.push({
//      start: parseFloat(sexMatch[2]),
//      end: parseFloat(sexMatch[3]),
//    });
//  }
//
//  // Extract general numeric ranges (e.g., "0.3 - 1.2")
//  const rangeRegex = /(\d+(\.\d+)?)\s*-\s*(\d+(\.\d+)?)/g;
//  let match;
//  while ((match = rangeRegex.exec(normalizedString)) !== null) {
//    ranges.push({ start: parseFloat(match[1]), end: parseFloat(match[3]) });
//  }
//
//  // Extract age-dependent ranges (e.g., "0 - 15 (Below 50 Male)")
//  const ageBasedRegex = new RegExp(
//    `(\\d+\\s*-\\s*\\d+)\\s*\\((below|above)?\\s*(\\d+)\\s*(${sex})?\\)`,
//    "gi"
//  );
//  let ageMatch;
//  while ((ageMatch = ageBasedRegex.exec(normalizedString)) !== null) {
//    const [fullMatch, rangePart, ageCondition, ageLimit] = ageMatch;
//    const [start, end] = rangePart.split("-").map(Number);
//
//    if (
//      (ageCondition === "below" && age < parseInt(ageLimit)) ||
//      (ageCondition === "above" && age >= parseInt(ageLimit))
//    ) {
//      ranges.push({ start, end });
//    }
//  }
//
//  return ranges.length > 0 ? ranges : null;
//};
//
//export default extractRange;

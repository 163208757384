import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import ci from "../../../../../../images/cutis_new.jpeg";
import priya from "../images/priya.png";
import Neena from "../images/Neena.png";
//import QRCodeWithLogoComponent from "./QRCodeComponent";
import Barcode from "react-barcode";
import extractRange from "./utils/extractRange";
//import { ReactComponent as Rupees } from "../../../../images/currency-rupee.svg";
//import { numberToWords } from "../../CRM-Calicut/utils/numberWords";

const PrintReport = React.forwardRef(
  (
    {
      address,

      date,

      branch,

      formData,
    },
    ref
  ) => {
    // const [printVisible, setPrintVisible] = useState(false);
    //  const options = {
    //    hour: "numeric",
    //    minute: "numeric",
    //    //  second: "numeric",
    //    timeZone: "Asia/Kolkata", // Set the desired time zone (Indian Standard Time)
    //  };
    //  const printDate = (
    //    <span style={{ fontSize: "10px" }}>
    //      {`${new Date(date).getDate()} / ${
    //        new Date(date).getMonth() + 1
    //      } /  ${new Date(date).getFullYear()} `}{" "}
    //      {
    //        //new Date(date).toLocaleTimeString("en-US", options)
    //      }
    //    </span>
    //  );

    //  const formatDate = (date) => {
    //    const options = { year: "numeric", month: "long", day: "numeric" };
    //    return new Intl.DateTimeFormat("en-US", options).format(date);
    //  };

    const formatDateTime = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };
    const [isSmallScreen, setIsSmallScreen] = useState(
      window.innerWidth <= 550
    );

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 550);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    const NormalRangeComponent = ({ value, rangeString, test, age, sex }) => {
      const range = extractRange(rangeString, sex, age);
      console.log("Extracted Range:", range, value);

      if (!range) {
        return <span>{value}</span>;
      }

      let isOutOfRange = range.every((r) => value < r.start || value > r.end);

      return (
        <div>
          <span
            style={{
              fontWeight: isOutOfRange ? "bold" : "normal",
              //  color: isOutOfRange ? "red" : "black",
            }}
          >
            {value}
          </span>
        </div>
      );
    };

    const CutisHeader = () => {
      return (
        <Row className="mx-0 px-4 pt-4">
          <Col
            md={8}
            sm={8}
            style={{
              marginBottom: "0px",
              //  width: "220px",
              marginLeft: "0px",
              paddingLeft: "0px",
            }}
          >
            {branch && branch === "CLT" && (
              <img
                src={ci}
                alt="image"
                style={{
                  marginLeft: "0px",
                  overflow: "hidden",
                  height: "auto",
                  width: "300px",
                  // opacity: "60%",
                }}
              />
            )}
          </Col>
          <Col sm={4} className="pt-3">
            <div style={{ lineHeight: "1", color: "grey" }}>
              <h6
                style={{
                  fontWeight: "bold",
                  marginBottom: "3px",
                }}
              >
                Cutis International
              </h6>
              <span>Medical Laboratory </span>
            </div>
          </Col>
          <div
            className="d-flex justify-content-center pb-2 "
            style={{ marginTop: "-10px" }}
          >
            <h6
              color="grey"
              style={{
                color: "grey",
                letterSpacing: "0.1em",
              }}
            >
              <b>REPORT </b>
            </h6>
          </div>
        </Row>
      );
    };
    const LabReportHeaders = () => {
      return (
        <>
          {branch && branch === "CLT" && <CutisHeader />}
          <Row className="ps-5">
            <Col
              md={10}
              sm={10}
              className="d-flex justify-content-start text-md"
              style={{ fontSize: "6px" }}
            ></Col>
          </Row>
          <Row
            style={{
              borderBottom: "2px solid transparent",
              borderImage: "linear-gradient(to right, #6C3483, #FB0000) 1",
            }}
            className="mx-0"
          ></Row>
          <Row className="pt-3 text-md" style={{ fontSize: "11px" }}>
            <Col sm={11} style={{ textAlign: "right" }}>
              <Barcode
                value="CIL123456"
                format="CODE128"
                width={2}
                height={40}
                displayValue={false}
                background="#ffffff"
                lineColor="#000000"
                fontSize={14}
              />
            </Col>
          </Row>
          <Row className="px-3">
            <Col sm={6} className="d-flex justify-content-start">
              <div style={{ fontSize: "14px", letterSpacing: "0.03em" }}>
                <div className="mb-1 d-flex justify-content-start">
                  <div style={{ width: "120px" }}>Patient / Mob</div>
                  <div style={{ fontWeight: "bold" }}>
                    : {formData.patientData?.name} /{" "}
                    {formData.patientData?.contact}
                  </div>
                </div>
                <div className="mb-1 d-flex justify-content-start">
                  <div style={{ width: "120px" }}> Age / Sex</div>
                  <div>
                    : {formData.patientData?.age} / {formData.patientData?.sex}
                  </div>
                </div>
                <div className="mb-1 d-flex justify-content-start">
                  <div style={{ width: "120px" }}> Referrer </div>
                  <div> : {formData.patientData?.referrer}</div>
                </div>
                <div className="mb-1 d-flex justify-content-start">
                  <div style={{ width: "120px" }}> Branch </div>
                  <div> : {formData.patientData?.branch}</div>
                </div>
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              <div
                style={{
                  fontSize: "14px",
                  letterSpacing: "0.03em",
                }}
              >
                <div className="mb-1 d-flex justify-content-start">
                  <div style={{ width: "150px" }}> SID No </div>
                  <div> : {formData.SID}</div>
                </div>

                <div className="mb-1 d-flex justify-content-start">
                  <div style={{ width: "150px" }}> Reg Date & Time </div>
                  <div>
                    {" "}
                    :{" "}
                    {formatDateTime(
                      new Date(formData.patientData?.regDateTime)
                    )}
                  </div>
                </div>

                <div className="mb-1 d-flex justify-content-start">
                  <div style={{ width: "150px" }}> Coll Date & Time </div>
                  <div>
                    {" "}
                    :{" "}
                    {formatDateTime(
                      new Date(formData.patientData?.collDateTime)
                    )}
                  </div>
                </div>

                <div className="mb-1 d-flex justify-content-start">
                  <div style={{ width: "150px" }}> Report Date & Time </div>
                  <div>
                    {" "}
                    :{" "}
                    {formatDateTime(
                      new Date(formData.patientData?.reportDateTime)
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "right" }} className="pe-4"></Col>
          </Row>
          <Row
            style={{
              backgroundColor: "grey",
              padding: "5px",
              fontWeight: "bold",
              fontSize: "14px",
              color: "white",
            }}
            className="mt-1 mx-2"
          >
            <Col sm={3} xs={4}>
              <b>TEST NAME</b>
            </Col>
            <Col sm={2} xs={2}>
              <b>RESULT</b>
            </Col>
            <Col sm={3} xs={4}>
              <b>REFERENCE RANGE</b>
            </Col>
            <Col sm={2} xs={2}>
              <b>UNITS</b>
            </Col>
            <Col sm={2} xs={3}>
              <b>METHOD</b>
            </Col>
          </Row>
        </>
      );
    };
    const Footer = () => {
      return (
        <>
          <Row className="px-5 mb-0 mt-0" style={{ marginTop: "-20px" }}>
            <Col md={5} sm={5} xs={5} style={{ fontSize: "12px" }}>
              {branch && branch === "CLT" && (
                <>
                  <div>
                    <img
                      src={Neena}
                      alt="image"
                      style={{
                        marginLeft: "0px",
                        overflow: "hidden",
                        height: "auto",
                        width: "150px",
                        // opacity: "60%",
                      }}
                    />
                    <div>Verified by</div>
                  </div>
                  Neena A.J.
                  <div>B.Sc(MLT)</div>
                </>
              )}
            </Col>
            <Col sm={2} xs={2}></Col>
            <Col
              md={5}
              sm={5}
              xs={5}
              style={{
                fontSize: "12px",
                textAlign: "right",
              }}
            >
              {branch && branch === "CLT" && (
                <>
                  <div>
                    <img
                      src={priya}
                      alt="image"
                      style={{
                        marginLeft: "0px",
                        overflow: "hidden",
                        height: "auto",
                        width: "150px",

                        // opacity: "60%",
                      }}
                    />
                  </div>

                  <div>Dr Gokula Priya M R M.D.,DNB </div>
                  <div> Consultant Pathologist</div>
                </>
              )}
            </Col>
            <Col>
              {
                //    <QRCodeWithLogoComponent />
              }
            </Col>
          </Row>
          <Row className="px-5 mb-0 mt-0 pb-0">
            <Col sm={12} px-3 className="d-flex justify-content-center mb-0">
              {" "}
              <div style={{ marginTop: "-7px", marginBottom: "0px" }}>
                <h6 style={{ marginBottom: "0px" }}>
                  <b>
                    <span>------------------------</span> End of the Report{" "}
                    <span>------------------------</span>
                  </b>
                </h6>
              </div>
            </Col>
          </Row>
        </>
      );
    };
    return (
      <div ref={ref}>
        <style>
          {`
          @media print {
            @page {
              size: auto; 
              margin: 10mm;
            }
            .page-break {
              page-break-before: always;
            }
          }
        `}
        </style>
        {formData.results?.map((result, id) => (
          <div key={id} className="page-break">
            <LabReportHeaders />
            <Container fluid className="pb-5 mb-5">
              <Row>
                <Col className="d-flex justify-content-center">
                  <div className="pt-1">
                    <h6>
                      <b>{result.categoryName}</b>
                    </h6>
                  </div>
                </Col>
              </Row>
              {result.tests?.map((test, idtest) => (
                <React.Fragment key={idtest}>
                  <Row className="px-1 mt-1" style={{ fontSize: "11px" }}>
                    <Col sm={3} md={3} xs={4}>
                      <b>{test.testName}</b>

                      <div style={{ marginTop: "-5px" }}>
                        {test.specimen && `Specimen: ${test.specimen}`}
                      </div>
                    </Col>
                    <Col sm={2} md={2} xs={2}>
                      {test.results && test.referenceRange ? (
                        <NormalRangeComponent
                          value={test.results}
                          rangeString={test.referenceRange}
                          test={test.testName}
                          age={formData.patientData.age}
                          sex={formData.patientData.sex}
                        />
                      ) : (
                        test.results
                      )}
                    </Col>
                    <Col sm={3} md={3} xs={3}>
                      {test.referenceRange}
                    </Col>
                    <Col sm={1} md={2} xs={2}>
                      {test.units}
                    </Col>
                    <Col sm={3} xs={4} className="break-word">
                      {test.method}
                    </Col>
                  </Row>
                  <Row className="px-3 mt-1" style={{ fontSize: "11px" }}>
                    <Col sm={12}>
                      <div style={{ marginTop: "10px" }}>
                        {test.notes && `Note: ${test.notes}`}
                      </div>
                    </Col>
                  </Row>
                  {test.subTests.map((subTest, subId) => (
                    <React.Fragment key={subId}>
                      <Row className="px-3 mt-1" style={{ fontSize: "11px" }}>
                        <Col sm={3}>
                          <b>{subTest.subTestName}</b>

                          <div style={{ marginTop: "-10px" }}>
                            {subTest.specimen &&
                              `Specimen: ${subTest.specimen}`}
                          </div>
                        </Col>
                        <Col sm={2}>
                          {subTest.results && subTest.referenceRange ? (
                            <NormalRangeComponent
                              value={subTest.results}
                              rangeString={subTest.referenceRange}
                              test={subTest.subTestName}
                              age={formData.patientData.age}
                              sex={formData.patientData.sex}
                            />
                          ) : (
                            subTest.results
                          )}
                        </Col>
                        <Col>{subTest.referenceRange}</Col>
                        <Col sm={1} xs={2}>
                          {subTest.units}
                        </Col>
                        <Col sm={3} xs={4} className="break-word">
                          {subTest.method}
                        </Col>
                      </Row>
                      {subTest.notes && (
                        <Row className="px-3 mt-1" style={{ fontSize: "11px" }}>
                          <Col sm={12}>
                            <div style={{ marginTop: "10px" }}>
                              Note: {subTest.notes}
                            </div>
                          </Col>
                        </Row>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </Container>
            <Footer />
          </div>
        ))}
        <div className="page-break">
          <Row>
            <Col className="d-flex justify-content-center">
              {
                //  <h6>
                //     <b>
                //       <span>----------------------------------------</span> End of
                //       the Report{" "}
                //       <span>----------------------------------------</span>
                //     </b>
                //   </h6>
              }
            </Col>
          </Row>
          <Row className="px-4 mt-4 pt-4"></Row>
          <Row className="px-3">
            <Col
              className="mt-3"
              style={{ fontSize: "11px", textAlign: "left" }}
            >
              <p></p>
              <p className="bill-terms"></p>
              <p className="mt-0"></p>
            </Col>
          </Row>
          <CutisHeader />
          <Row className="mt-2">
            <Col className="p-5">
              <div className="d-flex justify-content-center">
                <u>
                  <h6 className="mb-3">Conditions of Reporting</h6>
                </u>
              </div>
              <ol style={{ fontSize: "12px", lineHeight: "1.6" }}>
                <li>
                  Test reports relate to the specimen tested. Partial
                  reproduction of the report is not valid.
                </li>
                <li>
                  Individual laboratory investigations should not be considered
                  as conclusive and should be used along with other relevant
                  clinical examinations to achieve the final diagnosis.
                  Therefore, these reported results are for the information of
                  the referring clinician only.
                </li>
                <li>
                  For tests performed on specimens received from other labs,
                  hospitals, etc., it is presumed that the specimens belong to
                  the patient named and identified. Such verifications having
                  been carried out at the point of generation of the said
                  specimen.
                </li>
                <li>
                  Cutis Diagnostic Centre confirms that all tests have been
                  carried out with reasonable care, clinical safety, and
                  technical integrity.
                  <ul>
                    <li>
                      However, due to certain factors such as reagent
                      inconsistency, machine breakdown, etc. beyond its control
                      which could affect the testing, it does not make any
                      representation or give any warranty about the accuracy of
                      the reported results.
                    </li>
                    <li>
                      The test results are to be used for help in
                      diagnosing/treating medical diseases and not for forensic
                      applications. Hence, these results cannot be used for
                      medico-legal purposes.
                    </li>
                  </ul>
                </li>
                <li>
                  In the event of unforeseen circumstances (unavailability of
                  kits, failure of test runs, instrument breakdown), Cutis
                  International will make all efforts to minimize the delay in
                  reporting.
                </li>
                <li>
                  Histopathology, Cytology, and Peripheral smear reports cannot
                  be considered as “final diagnosis” since it needs correction
                  with clinical findings and other laboratory data. Slides and
                  blocks can be made available if necessary for a second
                  opinion, after getting a written request from the referred
                  clinician.
                </li>
              </ol>
            </Col>
          </Row>
          <Row className="footer-address d-flex justify-content-center">
            <Col
              sm={8}
              xs={8}
              className="pt-2 pe-1 pb-2 "
              style={{
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    color: "#8B0F7A",
                    fontSize: "14px",
                    fontWeight: "bold",
                    display: "block",
                    marginBottom: "5px",
                  }}
                >
                  {address.first_line}
                </span>
                <span style={{ fontSize: "12px" }}>{address.second_line}</span>
                <span style={{ fontSize: "12px" }}>
                  {address.third_line}, {address.fourth_line}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
);

export default PrintReport;

import axios from "axios";

const clearSessions = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("srchData");
  sessionStorage.removeItem("prescriptionHistory");
  sessionStorage.removeItem("rights");
};

export const IndianStates = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

// mrn optional for specific customer or all customers will be fetched
export const getCustomers = async (
  token,
  branch,
  mrn,
  doctorName,
  date,
  navigate,
  fromDate,
  toDate
) => {
  //console.log(date);
  try {
    const res = await axios.get(`${process.env.REACT_APP_CRI_API}customers`, {
      params: {
        branch: branch,
        mrn: mrn,
        doctorName: doctorName,
        date: date,
        fromDate,
        toDate,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    if (
      err.response.data &&
      (err.response.data.err.name === "TokenExpiredError" ||
        err.response.data.message === "Unauthorized")
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
    }
    console.log(err.response.data.err);
  }
};

export const getbranchData = async (token, branch, navigate) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_CRI_API}branch`, {
      params: {
        branch: branch,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    if (err.message) {
      // alert("NetWork Error");
      clearSessions();
      //   navigate("/");
      return err;
    }
    if (
      (err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      // navigate("/");
      clearSessions();
      navigate("/");
      return err;
    }
    console.log(err.response.data.err);
  }
};

export const getDoctors = async (token, branch, navigate) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_CRI_API}doctors`, {
      params: {
        branch: branch,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    if (
      (err.response &&
        err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
    console.log(err.response.data.err);
  }
};

export const getCustomerCareList = async (
  token,
  branch,
  contactno,
  date,
  navigate
) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_CRI_API}customercare`,
      {
        params: {
          branch: branch,
          contactno: contactno,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
    if (
      (err.response &&
        err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
    console.log(err.response.data.err);
  }
};

export const getPrescriptions = async (token, branch, mrn, date, navigate) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_CRI_API}prescript`, {
      params: {
        branch: branch,
        mrn: mrn,
        date: date,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    if (
      (err.response &&
        err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
    console.log(err.response.data.err);
  }
};

export const getAppointments = async (
  token,
  selectedDate,
  branch,
  frmDate,
  doctorName,
  department,
  navigate
) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_CRI_API}appointments`,
      {
        params: {
          date: selectedDate,
          fromDate: frmDate,
          branch: branch,
          doctorName: doctorName,
          department,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //console.log(res);
    return res.data;
  } catch (err) {
    console.log(err);
    // if (
    //   (err.response &&
    //     err.response.data &&
    //     err.response.data.err.name === "TokenExpiredError") ||
    //   err.response.data.message === "Unauthorized"
    // )
    {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
  }
};

export const getCountries = async (token, country_code, navigate) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_CRI_API}countries`, {
      params: {
        country_code: country_code,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    if (
      (err.response &&
        err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
  }
};
//get-invoices
export const getInvoices = async (
  token,
  selectedDate,
  branch,
  username,
  frmDate,
  navigate,
  toDate
) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_CRI_API}get-invoices`,
      {
        params: {
          date: selectedDate,
          branch,
          username,
          frmDate,
          toDate,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    const errorResponse = err.response?.data;

    // Check for TokenExpiredError or Unauthorized message
    if (
      errorResponse?.err?.name === "TokenExpiredError" ||
      errorResponse?.message === "Unauthorized"
    ) {
      // Clear session storage
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");

      // Navigate to appropriate route
      navigate("/"); // Possibly a login page
      navigate("/cri-crm"); // Another destination after clearing data

      return err; // Return the error if needed
    }
  }
};

export const getPharmacyInvoices = async (
  token,
  selectedDate,
  branch,
  username,
  frmDate,
  navigate
) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_CRI_API}phmcyinvoices`,
      {
        params: {
          date: selectedDate,
          branch,
          username,
          frmDate,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (
      (err.response &&
        err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
  }
};

export const searchCustomers = async (token, searchInput, branch, navigate) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_CRI_API}searchcustomers`,
      {
        params: {
          branch: branch,
          search: searchInput,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  } catch (err) {
    console.log(err);
    if (
      (err.response &&
        err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
  }
};

export const searchInvoices = async (token, searchInput, branch, navigate) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_CRI_API}searchinvoices`,
      {
        params: {
          branch: branch,
          search: searchInput,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  } catch (err) {
    if (
      (err.response &&
        err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
  }
};

export const deleteAppointmentHandler = async (
  token,
  branch,
  datewithoutHours,
  _id,
  tempmrn,
  navigate
) => {
  try {
    axios
      .delete(`${process.env.REACT_APP_CRI_API}delete-appointment`, {
        params: {
          date: datewithoutHours,
          id: _id,
          branch: branch,
          tempmrn: tempmrn,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res) {
          return res.data;
          //   setAppointmentData(res.data);
          // setBookedSlots("");
        }
      });
  } catch (err) {
    if (
      (err.response &&
        err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
  }
};

export const addAppointment = async (token, branch, postData, navigate) => {
  try {
    axios
      .post(`${process.env.REACT_APP_CRI_API}add-appointment`, postData, {
        params: {
          branch: branch,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  } catch (err) {
    navigate("/");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("srchData");
    sessionStorage.removeItem("prescriptionHistory");
    sessionStorage.removeItem("rights");
    navigate("/cri-crm");
    return err;
  }
};

export const getProcedures = async (token, branch, navigate) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_CRI_API}procedures`, {
      params: {
        branch: branch,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res;
  } catch (err) {
    navigate("/");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("srchData");
    sessionStorage.removeItem("prescriptionHistory");
    sessionStorage.removeItem("rights");
    navigate("/cri-crm");
    return err;
  }
};

export const getInventoryItems = async (token, branch, navigate) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_CRI_API}items`, {
      params: {
        branch: branch,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res;
  } catch (err) {
    if (
      (err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
  }
};

export const searchInventoryItems = async (
  token,
  searchInput,
  branch,
  navigate
) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_CRI_API}searchInventory`,
      {
        params: {
          branch: branch,
          search: searchInput,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  } catch (err) {
    if (
      (err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
  }
};

export const editInvoice = async (id, editData, branch, token, navigate) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_CRI_API}edit-invoice`,
      editData,
      {
        params: {
          branch: branch,
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      console.log(res.data);
      return res.data;
    }
  } catch (err) {
    if (
      (err.response &&
        err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
    console.log(err);
    return err;
  }
};

export const addRtnInvoice = async (id, editData, branch, token, navigate) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_CRI_API}add-rtn-invoice`,
      editData,
      {
        params: {
          branch: branch,
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      console.log(res.data);
      return res.data;
    }
  } catch (err) {
    if (
      (err.response &&
        err.response.data &&
        err.response.data.err.name === "TokenExpiredError") ||
      err.response.data.message === "Unauthorized"
    ) {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("srchData");
      sessionStorage.removeItem("prescriptionHistory");
      sessionStorage.removeItem("rights");
      navigate("/cri-crm");
      return err;
    }
    console.log(err);
    return err;
  }
};

import React, { useState } from "react";
import axios from "axios";
import { Spinner } from "reactstrap";

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

const FileUpload = ({ formData, setFormData, inputKey, mrn }) => {
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Handle file selection
  const handleFileChange = (e) => {
    console.log(mrn);
    const selectedFiles = Array.from(e.target.files);

    if (!selectedFiles.length) {
      setUploadStatus("No files selected.");
      return;
    }

    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
    ];

    // Validate files
    const validFiles = selectedFiles.filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        setUploadStatus(`Invalid file type: ${file.name}`);
        return false;
      }
      if (file.size > MAX_FILE_SIZE) {
        setUploadStatus(`File too large: ${file.name} (Max: 2MB)`);
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) {
      setFiles([]);
      return;
    }

    setFiles(validFiles);
    setUploadStatus("");
  };

  // Handle multiple file uploads
  const handleUpload = async () => {
    setIsLoading(true);

    if (files.length === 0) {
      alert("Please select valid files to upload.");
      setIsLoading(false);
      return;
    }

    try {
      const uploadedFileKeys = [];

      for (const file of files) {
        console.log(file, files);
        // Get pre-signed URL for each file
        const response = await axios.get(
          `${process.env.REACT_APP_CRI_API}get-upload-url`,
          {
            params: { fileName: file.name, mrn: mrn },
          }
        );

        const { uploadUrl, fileKey } = response.data;

        // Upload file to S3
        await axios.put(uploadUrl, file, {
          headers: { "Content-Type": file.type },
        });

        uploadedFileKeys.push(fileKey);
      }

      alert("All files uploaded successfully!");

      // Update formData with uploaded file keys
      setFormData({
        ...formData,
        files: [...(formData.files || []), ...uploadedFileKeys],
      });

      setIsLoading(false);
    } catch (error) {
      console.error("Upload failed:", error);
      alert("File upload failed!");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h5>Upload Files (Max: 2MB each)</h5>
      <input
        type="file"
        accept="application/pdf, image/*"
        multiple
        onChange={handleFileChange}
        key={inputKey}
      />

      {mrn && (
        <button
          onClick={handleUpload}
          disabled={files.length === 0 || isLoading}
        >
          {isLoading ? <Spinner size="sm">{""}</Spinner> : "Upload"}
        </button>
      )}

      {uploadStatus && <p>{uploadStatus}</p>}

      {files.length > 0 && (
        <ul>
          {files.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FileUpload;

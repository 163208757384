export const fetchSignedUrls = async (fileKeys) => {
  try {
    if (!Array.isArray(fileKeys) || fileKeys.length === 0) {
      console.error("Invalid file keys");
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_CRI_API}signedUrl`, // Adjust endpoint for multiple files
      {
        method: "POST", // Use POST for sending an array
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fileKeys }),
      }
    );

    const data = await response.json();

    if (data.urls && data.urls.length > 0) {
      // Open all files in new tabs
      data.urls.forEach((url) => {
        window.open(url, "_blank");
      });
    } else {
      console.error("Failed to retrieve signed URLs");
    }
  } catch (error) {
    console.error("Error fetching signed URLs:", error);
  }
};

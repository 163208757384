import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  getAppointments,
  getCustomers,
  searchInventoryItems,
} from "../../../Data/Customer/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import CrmCLT from "./CrmCLT";
import { DownloadCloud, RefreshCcw } from "react-feather";
import AsyncSelect from "react-select/async";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import PrintComponent from "./PrintComponent";
import FileUpload from "../../SubComponents/FileUpload";
import { fetchSignedUrls } from "../../utils/fileUtils";

const Treatment = (args) => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const location = useLocation();
  const auth = useSelector((state) => state.authentication);
  const { userData, branchData } = auth;
  const branch = JSON.parse(sessionStorage.getItem("branch"));
  const username = userData.user;
  const navigate = useNavigate();
  const componentRef = useRef();

  const date = new Date();

  // saving pupose, always put this before date.setHours, which modifies date itself
  const mdate = new Date();
  // searching purpose
  const datewithoutHours = new Date(mdate.setHours(0, 0, 0, 0));
  const [customerData, setCustomerData] = useState();
  const [diagnosis, setDiagnosis] = useState("");
  const [prescription, setPrescription] = useState("");
  const [specialAdvice, setSpecialAdvice] = useState("");
  const [address, setAddress] = useState("");
  const [printEnable, setprintEnable] = useState(false);
  const [addressData, setAddressData] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [selectedCm, setSelectCm] = useState("");
  const [customerHistory, setCustomerHistory] = useState("");
  const [mHeader, setMHeader] = useState("Patient List");
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [saved, setSaved] = useState(false);
  const [complaintsnHistory, setComplaintsnHistory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [prescriptionNotes, setPrescriptionNotes] = useState("");
  const [printData, setPrintData] = useState({
    addressData,
    selectedCm,
    date,
    username,
    complaintsnHistory,
    diagnosis,
    prescription,
    prescriptionNotes,
    specialAdvice,
  });
  const [formData, setFormData] = useState("");
  // for fileupload data

  //
  // const formatDate = (date) => {
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   return new Intl.DateTimeFormat("en-US", options).format(date);
  // };
  // const DatetimeFormatHandler = (props) => {
  //   const datentime = (
  //     <span>
  //       {" "}
  //       {`${props.date.getDate()} / ${
  //         props.date.getMonth() + 1
  //       } /  ${props.date.getFullYear()} `}{" "}
  //       {props.date.toLocaleTimeString()}
  //     </span>
  //   );
  //
  //   return datentime;
  // };
  const getCustomerHandler = () => {
    processAppointments(token, datewithoutHours, branch, username);
    // calls for appinment and customer data

    //  getCustomers(branch, "", "", `${formatDate(date)}`).then((res) => {
    //    console.log(res);
    //    setCustomerData(res);
    //  });
  };
  const processAppointments = async (
    token,
    datewithoutHours,
    branch,
    username,
    frmDate
  ) => {
    try {
      // Step 1: Fetch MRNs from appointment data
      // token, selectedDate, branch, frmDate, doctorName,  department
      const appointments = await getAppointments(
        token,
        datewithoutHours,
        branch,
        "",
        username,
        "",
        navigate
      );

      setSelectedAppointment(() =>
        appointments.filter((item) => !item.mrn.includes("Tmp"))
      );

      const mrns = appointments.map((appointment) => appointment.mrn);

      // Step 2: Fetch customer data for each MRN using Promise.all
      //  token,
      //  branch,
      //  mrn,
      //  doctorName,
      //  date,
      //  navigate
      const customerDataPromises = mrns.map((mrn) =>
        getCustomers(token, branch, mrn, "", "", navigate).then((res) => res)
      );

      //console.log(mrns);

      const customerDataArray = await Promise.all(customerDataPromises);
      setCustomerData(customerDataArray.flat());
      // customerDataArray now contains an array of customer data corresponding to each MRN
      //console.log(customerDataArray.flat());
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    processAppointments(token, datewithoutHours, branch, username);
    // selectedDate, branch, username, frmDate
  }, [branch, username, token]);

  useEffect(() => {
    if (branchData.length > 0) {
      const { first_line, second_line, third_line, fourth_line } =
        branchData[0].address;

      setAddress({
        first_line,
        second_line,
        third_line,
        fourth_line,
      });
    }
  }, [branchData]);

  const clearFom = () => {
    setPrescription("");
    setSpecialAdvice("");
    setDiagnosis("");
    setComplaintsnHistory("");
    setPrescriptionNotes("");
    setPrintData(() => {});
  };

  useEffect(() => {
    // while mrn gets changed
    //  if (selectedCm.mrn) {
    //    setPrescription("");
    //    setSpecialAdvice("");
    //    setDiagnosis("");
    //    setComplaintsnHistory("");
    //    setPrescriptionNotes("");
    //  }
    //console.log(selectedCm);
  }, [selectedCm.mrn]);

  const postData = {
    mrn: selectedCm ? selectedCm.mrn : "",
    branch,
    date: datewithoutHours, // added back sethours
    fullDate: date,
    doctorName: username,
    diagnosis,
    prescription,
    prescriptionNotes,
    specialAdvice,
    complaintsnHistory,
    ...formData,
  };

  const getCustomerHistory = () => {
    //date, doctorName, branch, mrn
    setIsLoadingHistory(true);
    axios
      .get(`${process.env.REACT_APP_CRI_API}prescript`, {
        params: {
          branch: branch,
          doctorName: username,
          mrn: selectedCm ? selectedCm.mrn : "",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {
          setCustomerHistory(res.data);
          // setPrintData(() => ({
          //   specialAdvice: res.data.specialAdvice,
          //   prescriptionNotes: res.data.prescriptionNotes,
          //   prescription: Array.isArray(res.data.prescription)
          //     ? res.data.prescription.map((pres) => pres.label).join(", ")
          //     : "",
          //   diagnosis: res.data.diagnosis,
          //   complaintsnHistory: res.data.complaintsnHistory,
          // }));
        }
      })
      .then(() => {
        setMHeader("Patient History");
        setIsLoadingHistory(false);
      })
      .catch((err) => {
        console.log(err);
        if (
          (err.response.data &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }
      });
  };

  const submitHandler = () => {
    if (!formData.files) {
      if (
        window.confirm("Do you want to continue saving without file upload")
      ) {
      } else {
        return;
      }
    }
    if (saved) {
      alert("Already Saved");
      return;
    }

    if (!prescription && !diagnosis) {
      alert("Kindly enter Diagnosis and Prescription");

      return;
    }
    if (selectedCm && selectedCm.mrn) {
      setIsLoading(true);
      axios
        .post(`${process.env.REACT_APP_CRI_API}add-prescript`, postData, {
          params: {
            branch: branch,
            doctorName: username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          //console.log(res.status == 200);
          if (res.status == 200) {
            setPrintData((prev) => ({
              ...prev,
              ...selectedCm,
              specialAdvice: res.data.specialAdvice,
              prescriptionNotes: res.data.prescriptionNotes,
              prescription: Array.isArray(res.data.prescription)
                ? res.data.prescription.map((pres) => pres.label).join(", ")
                : "",
              diagnosis: res.data.diagnosis,
              complaintsnHistory: res.data.complaintsnHistory,
              fullDate: res.data.fullDate,
            }));

            alert("Save Successful");
            setprintEnable(true);
            //  setSelectCm(selectedCm);
            getCustomerHistory();
            setSaved(true);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          if (
            (err.response.data &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
        });
    } else {
      alert("No Patient Appointment");
    }
  };

  const selectCmHandler = (cm) => {
    setSelectCm(cm);
    toggle();
  };

  //  useEffect(() => {
  //    if (prescription) {
  //      searchInventoryItems(token, prescription, branch, navigate).then(
  //        (res) => {
  //          console.log(res);
  //          setSearchResult(res);
  //        }
  //      );
  //    }
  //  }, [prescription]);

  const loadOptions = async (inputValue, callback) => {
    try {
      // Perform fetch operation to get search results based on inputValue
      const response = await searchInventoryItems(
        token,
        inputValue,
        branch,
        navigate
      );
      const seenLabels = new Set();
      const data = await response;
      const options = data
        .map((item) => ({
          value: item._id,
          label: item.item, // Ensure this is a string
        }))
        .filter((option) => {
          if (!option.label || seenLabels.has(option.label)) {
            return false;
          }
          seenLabels.add(option.label);
          return true;
        });

      callback(options);
    } catch (error) {
      console.error("Error fetching options:", error);
      callback([]);
    }
  };

  const CmList = () => {
    return (
      customerData &&
      customerData.map((item, id) => {
        return (
          <Card key={id} className="mt-3">
            <CardHeader>
              {item.fname + " " + item.sname}
              <div style={{ fontSize: "11px" }}>{item.mrn}</div>
              <Button
                color="primary"
                style={{
                  position: "absolute",
                  right: "3px",
                  top: "5px",
                }}
                onClick={() => {
                  clearFom();
                  selectCmHandler(item);
                }}
              >
                Select
              </Button>
            </CardHeader>
            <CardBody>
              <div>
                <b className="me-1">Gender:</b>
                {item.sex}
              </div>
              <div>
                <b className="me-1">DOB:</b>
                {item.dob}
              </div>
              <div>
                <b className="me-1">Treatment:</b> {item.treatmenttype}
              </div>
              <div>
                <b className="me-1">Past Prescription:</b>{" "}
                {item.pastPrescription}
              </div>
              <div>
                <b className="me-1">Drug Allergies</b> {item.reactionAlergies}
              </div>
              <div>
                <b className="me-1">Patient Coordinator:</b>{" "}
                {item.patientcoordinator}
              </div>
              <div>
                <b className="me-1">Remarks:</b> {item.remarks}
              </div>
            </CardBody>
          </Card>
        );
      })
    );
  };

  const CustomerHistoryModalContent = () => {
    const {
      fname,
      sname,
      treatmenttype,
      reactionAlergies,
      patientcoordinator,
      remarks,
      pastPrescription,
      sex,
      dob,
    } = selectedCm && selectedCm;
    //console.log(customerHistory);
    if (isLoadingHistory)
      return (
        <Row>
          <Col className="d-flex justify-content-center">
            <Spinner color="warning" />
          </Col>
        </Row>
      );
    return (
      customerHistory &&
      customerHistory
        .sort((a, b) => new Date(b.fullDate) - new Date(a.fullDate))
        .map((item, id) => {
          return (
            <Card key={id} className="mt-3">
              <CardHeader>
                {fname + " " + sname}
                <div style={{ fontSize: "11px" }}>{item.mrn}</div>
                {
                  <ReactToPrint
                    onBeforeGetContent={() => {
                      console.log(item);
                      setPrintData((prev) => ({
                        ...prev,
                        ...selectedCm,
                        ...item,
                        prescription: Array.isArray(item.prescription)
                          ? item.prescription
                              .map((pres) => pres.label)
                              .join(", ")
                          : "",
                        diagnosis: item.diagnosis,
                      }));

                      setSpecialAdvice(() => item.specialAdvice);
                      setPrescriptionNotes(() => item.prescriptionNotes);
                      setPrescription(() =>
                        Array.isArray(item.prescription)
                          ? item.prescription
                              .map((pres) => pres.label)
                              .join(", ")
                          : ""
                      );
                      setDiagnosis(() => item.diagnosis);
                      setComplaintsnHistory(() => item.complaintsnHistory);
                      return new Promise((resolve) => {
                        // Ensure state updates are complete before proceeding
                        setTimeout(resolve, 0);
                      });
                    }}
                    trigger={() => (
                      <button
                        className="glass-button"
                        style={{
                          position: "absolute",
                          right: "3px",
                          top: "5px",
                        }}
                      >
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                }
              </CardHeader>
              <CardBody>
                <div>
                  <b>Date:</b>
                  <span>
                    {" "}
                    {`${new Date(item.date).getDate()} / ${
                      new Date(item.date).getMonth() + 1
                    } /  ${new Date(item.date).getFullYear()} `}{" "}
                    {new Date(item.fullDate).toLocaleTimeString()}
                  </span>
                </div>

                <div>
                  <b className="me-1">Treatment:</b> {treatmenttype}
                </div>

                <div>
                  <b className="me-1">Remarks:</b> {remarks}
                </div>
                {
                  // Doc's prescription
                }
                <div>
                  <Label for="Complaints"> Complaints/History:</Label>
                  <Input
                    type="textarea"
                    name="text"
                    id="diagnosis"
                    readOnly
                    rows="5"
                    value={item.complaintsnHistory}
                  />
                  <Label for="diagnosis"> Diagnosis:</Label>
                  <Input
                    type="textarea"
                    name="text"
                    id="diagnosis"
                    rows="3"
                    readOnly
                    value={item.diagnosis}
                  />
                  <Label for="prescription"> Prescriptions:</Label>
                  <Input
                    type="textarea"
                    name="text"
                    rows="2"
                    id="prescription"
                    readOnly
                    value={
                      Array.isArray(item.prescription)
                        ? item.prescription.map((pres) => pres.label).join(", ")
                        : ""
                    }
                  />
                  <Label for="prescription"> Prescriptions Notes:</Label>
                  <Input
                    type="textarea"
                    name="text"
                    rows="5"
                    id="prescription"
                    readOnly
                    value={item.prescriptionNotes}
                  />
                  <Label for="specialAdvice"> Special advice:</Label>
                  <Input
                    type="textarea"
                    name="text"
                    rows="5"
                    id="specialadvice"
                    value={item.specialAdvice}
                    readOnly
                  />
                </div>
              </CardBody>
              <CardFooter>
                <DownloadCloud onClick={() => fetchSignedUrls(item.files)} />
              </CardFooter>
            </Card>
          );
        })
    );
  };

  return (
    <>
      <Container>
        <Card>
          <CardHeader className="d-flex justify-content-center bg-primary mt-1 text-white pt-3">
            <h4>Treatment & Prescription</h4>{" "}
            <div
              className="pt-2 pe-2"
              style={{ position: "absolute", right: "5px" }}
            >
              <b>Consultant:</b>
              <span className="mr-2 ms-2">{username}</span>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="d-flex justify-content-end ">
              <Col md={4} sm={3} style={{ textAlign: "right" }}>
                <Button
                  outline
                  onClick={() => {
                    navigate("/otBooking");
                  }}
                  color="primary"
                >
                  {" "}
                  Appointments
                </Button>
                <Button
                  outline
                  onClick={() => {
                    navigate("/lab-admin");
                  }}
                  color="primary"
                  className="ms-4"
                >
                  {" "}
                  Lab
                </Button>

                <Button
                  outline
                  onClick={() => {
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("srchData");
                    sessionStorage.removeItem("prescriptionHistory");
                    sessionStorage.removeItem("rights");
                    navigate("/cri-crm");
                  }}
                  color="primary"
                  className="ms-4"
                >
                  Log Out
                </Button>
              </Col>
            </Row>
            <Row className="d-flex justify-content-start">
              <div
                className=" d-flex justify-content-start mb-3"
                style={{ width: "100%" }}
              >
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    getCustomerHandler();
                    toggle();
                    setMHeader("Patient List");
                  }}
                >
                  Notifications{" "}
                  <Badge color="primary">
                    {
                      // customerData && customerData.length
                    }
                    {selectedAppointment && selectedAppointment.length}
                  </Badge>
                </Button>
                <RefreshCcw
                  style={{ cursor: "pointer" }}
                  className="mt-2 ms-3"
                  onClick={getCustomerHandler}
                />
              </div>
              {selectedCm && (
                <div className="mb-3" style={{ width: "210px" }}>
                  <Button
                    color="primary"
                    outline
                    onClick={() => {
                      getCustomerHistory();
                      toggle();
                      setMHeader("Patient History");
                    }}
                  >
                    Patient History{" "}
                    <Badge color="primary">
                      {customerHistory && customerHistory.length}
                    </Badge>
                  </Button>
                </div>
              )}
            </Row>
            <Row>
              <Col md={3}>
                {selectedCm && (
                  <Card>
                    <CardHeader>
                      {selectedCm.fname + " " + selectedCm.sname}
                      <div style={{ fontSize: "11px" }}>{selectedCm.mrn}</div>
                    </CardHeader>
                    <CardBody>
                      <p>
                        <b className="me-1">Gender:</b>
                        {selectedCm.sex}
                      </p>
                      {selectedCm.dob && (
                        <p>
                          <b className="me-1">DOB:</b>
                          {selectedCm.dob}
                        </p>
                      )}
                      {selectedCm.age && (
                        <p>
                          <b className="me-1">Age:</b>
                          {selectedCm.age}
                        </p>
                      )}
                      <p>
                        <b className="me-1">Treatment:</b>{" "}
                        {selectedCm.treatmenttype}
                      </p>
                      <p>
                        <b className="me-1">Past Prescription:</b>{" "}
                        {selectedCm.pastPrescription}
                      </p>
                      <p>
                        <b className="me-1">Drug Allergies</b>{" "}
                        {selectedCm.reactionAlergies}
                      </p>
                      <p>
                        <b className="me-1">Patient Coordinator:</b>{" "}
                        {selectedCm.patientcoordinator}
                      </p>
                      <p>
                        <b className="me-1">Remarks:</b> {selectedCm.remarks}
                      </p>
                    </CardBody>
                  </Card>
                )}
              </Col>
              <Col style={{ width: "100%" }}>
                <Card style={{ width: "100%" }}>
                  <CardHeader className="d-flex justify-content-start mt-1">
                    <h5>Consultation Record</h5>{" "}
                    <div
                      className="pt-2 pe-2"
                      style={{
                        position: "absolute",
                        right: "5px",
                        fontSize: "12px",
                      }}
                    >
                      Consultation on:{" "}
                      {`${date.getDate()} / ${
                        date.getMonth() + 1
                      } /  ${date.getFullYear()} `}{" "}
                      {date.toLocaleTimeString()}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Label for="Complaints"> Complaints/History:</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="diagnosis"
                      value={complaintsnHistory}
                      onChange={(e) => {
                        setComplaintsnHistory(e.target.value);
                        setSaved(false);
                      }}
                    />
                    <Label for="diagnosis"> Diagnosis:</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="diagnosis"
                      rows="3"
                      value={diagnosis}
                      onChange={(e) => {
                        setDiagnosis(e.target.value);
                        setSaved(false);
                      }}
                    />
                    <Label for="prescription"> Prescriptions:</Label>
                    <AsyncSelect
                      style={{ height: "200px" }}
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptions}
                      value={prescription}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        setPrescription(selectedOption);
                      }}
                      isMulti
                    />
                    <Input
                      type="textarea"
                      name="text"
                      rows="5"
                      placeholder="Prescription Notes"
                      id="prescription"
                      value={prescriptionNotes}
                      onChange={(e) => {
                        setPrescriptionNotes(e.target.value);
                        //fetchSearchResults(e.target.value);
                        setSaved(false);
                      }}
                    />
                    <div>
                      {searchResult &&
                        searchResult.map((invItem) => {
                          return invItem.Item;
                        })}
                    </div>
                    <Label for="specialAdvice"> Special advice:</Label>
                    <Input
                      type="textarea"
                      name="text"
                      rows="5"
                      id="specialadvice"
                      value={specialAdvice}
                      onChange={(e) => {
                        setSpecialAdvice(e.target.value);
                        setSaved(false);
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end">
              <Col md={4} sm={4}>
                <FileUpload
                  formData={formData}
                  setFormData={setFormData}
                  // inputKey={inputKey}
                  mrn={selectedCm.mrn}
                />
              </Col>

              <Col md={3} sm={3} className="pt-2">
                {isLoading && <Spinner color="primary" type="grow"></Spinner>}
                {
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        onBeforeGetContent={() => {
                          console.log(printData);
                          setPrintData((prev) => ({
                            ...prev,
                            specialAdvice,
                            prescriptionNotes,
                            prescription,
                            diagnosis,
                            complaintsnHistory,
                          }));

                          return new Promise((resolve) => {
                            // Ensure state updates are complete before proceeding
                            setTimeout(resolve, 0);
                          });
                        }}
                        //   style={{
                        //     position: "absolute",
                        //     right: "3px",
                        //     top: "5px",
                        //   }}
                      >
                        Print
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />
                }
                {!isLoading && (
                  <Button
                    onClick={submitHandler}
                    className="bg-primary"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <div>
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}>{mHeader}</ModalHeader>
          <ModalBody>
            <Card style={{ border: "none" }}>
              <CardBody style={{ border: "none" }}>
                {mHeader === "Patient List" ? (
                  <CmList />
                ) : (
                  <CustomerHistoryModalContent />
                )}
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div style={{ display: "none" }}>
          <PrintComponent
            ref={componentRef}
            branch={userData.branch}
            // address={address}
            data={printData}
          />
        </div>
      </div>
    </>
  );
};

export default Treatment;

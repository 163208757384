import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Label,
  Row,
  Col,
} from "reactstrap";
import ci from "../../../images/cutis_new.jpeg";

const PrintComponent = React.forwardRef(({ branch, data }, ref) => {
  console.log(data);
  return (
    <div ref={ref} className="print-container">
      <style>
        {`
        @media print {
          @page {
            size: A4;
            margin: 10mm;
          }
          body {
            font-size: 12px;
            color: #000;
          }
          .page-break {
            page-break-before: always;
          }
          .no-print {
            display: none;
          }
        }
      `}
      </style>
      <Container>
        <Card
          className="mb-1 mt-1"
          style={{ width: "100%", fontSize: "12px", border: "1px solid #000" }}
        >
          <CardHeader className="bg-white text-center p-3 border-bottom">
            <Row className="align-items-center">
              <Col md="4" sm="6" className="text-center">
                <img src={ci} alt="Clinic Logo" style={{ width: "180px" }} />
              </Col>
              <Col md="8" sm="6" className="text-center">
                <h6 className="mb-0 font-weight-bold">Consultation Record</h6>
                <p className="mb-0" style={{ fontSize: "11px" }}>
                  {branch?.address}
                </p>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="6">
                <strong>Patient Name:</strong> {data?.fname}{" "}
                {data?.selectedCm?.sname} <br />
                <strong>Contact No:</strong> {data?.contactno} <br />
                <strong>MRN:</strong> {data?.mrn}
              </Col>
              <Col md="6" className="text-md-end">
                <strong>Doctor:</strong> {data?.doctorName} <br />
                <strong>Consultation on:</strong>{" "}
                {`${new Date(data?.fullDate).getDate()} / ${
                  new Date(data?.fullDate).getMonth() + 1
                } /  ${new Date(data?.fullDate).getFullYear()} `}{" "}
                {new Date(data?.fullDate).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </Col>
            </Row>
            <Label>
              <strong>Complaints / History:</strong>
            </Label>
            <p className="border p-2">{data?.complaintsnHistory}</p>
            <Label>
              <strong>Diagnosis:</strong>
            </Label>
            <p className="border p-2">{data?.diagnosis}</p>
            <Label>
              <strong>Prescriptions:</strong>
            </Label>
            <p className="border p-2">{data?.prescription}</p>
            <Label>
              <strong>Prescription Notes:</strong>
            </Label>
            <p className="border p-2">{data?.prescriptionNotes}</p>
            <Label>
              <strong>Special Advice:</strong>
            </Label>
            <p className="border p-2">{data?.specialAdvice}</p>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
});

export default PrintComponent;

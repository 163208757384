import { useRoutes, Navigate } from "react-router-dom";
import Login from "../Components/View/Authentication/Login";
//import CRM from "../Components/View/CRM/CRM";
import React, { Suspense, lazy } from "react";
import Booking from "../Components/View/CRM/OT/Booking";
import CrmCLT from "../Components/View/CRM-Calicut/CrmCLT";
import Treatment from "../Components/View/CRM-Calicut/Treatment";
import Pharmacy from "../Components/View/CRM-Calicut/Pharmacy";
//import Print from "../Components/View/CRM-Calicut/PrintHandler";
import Ccare from "../Components/View/CRM-Calicut/Ccare";
import CustomerForm from "../Components/View/CRM-Calicut/CustomerForm";
import Unauthorized from "../Components/View/CRM/Unauthorized";
import AdminPage from "../Components/View/CRM-Calicut/Pharmacy/AdminPage";
import InventoryForm from "../Components/View/CRM-Calicut/Pharmacy/InventoryForm";
import Suppliers from "../Components/View/CRM-Calicut/Pharmacy/Suppliers";
import StockManagementPage from "../Components/View/CRM-Calicut/Pharmacy/StockManagement";
import InPatient from "../Components/View/CRM-Calicut/Pharmacy/InPatient";
import Purchase from "../Components/View/CRM-Calicut/Pharmacy/Purchase/Purchase";
import Consumption from "../Components/View/CRM-Calicut/Pharmacy/Consumption";
import Reports from "../Components/View/CRM-Calicut/Pharmacy/Reports/Reports";
import SalesInvoices from "../Components/View/CRM-Calicut/Reports/SalesInvoices";
import ProceduresManagement from "../Components/View/CRM-Calicut/Pharmacy/Procedures.js/ProceduresManagement";
import RemovedItems from "../Components/View/CRM-Calicut/Pharmacy/RemovedItems";
import Lab from "../Components/View/CRM-Calicut/Pharmacy/lab/Lab";
import LabManagement from "../Components/View/CRM-Calicut/Pharmacy/lab/LabManagement";
import LabCategory from "../Components/View/CRM-Calicut/Pharmacy/lab/LabCategory";
import LabReportEntry from "../Components/View/CRM-Calicut/Pharmacy/lab/reports/LabReportEntry";
import LabResultsList from "../Components/View/CRM-Calicut/Pharmacy/lab/reports/LabResultsList";

const ItemViceView = lazy(() =>
  import("../Components/View/CRM-Calicut/Reports/ItemViceView")
);
const CRMReports = lazy(() => import("../Components/View/CRM/CRMReports"));
const QueueManagement = lazy(() =>
  import("../Components/View/QueManagement/QueManagement")
);
const QueueSystem = lazy(() =>
  import("../Components/View/QueManagement/QueueSystem")
);
const BillEntry = lazy(() => import("../Components/View/CRM/Bills/BillEntry"));
const UserManagement = lazy(() =>
  import("../Components/View/Admin/userManagement/UserManagement")
);

const Accounts = lazy(() => import("../Components/View/CRM-Calicut/Accounts"));
const Admin = lazy(() => import("../Components/View/Admin/Admin"));
const RtnSalesInvoices = lazy(() =>
  import("../Components/View/CRM-Calicut/Reports/RtnSalesInvoices")
);
const PurchaseReturns = lazy(() =>
  import("../Components/View/CRM-Calicut/Pharmacy/Purchase/PurchaseReturns")
);
const BillTabs = lazy(() => import("../Components/View/CRM/Bills/BillingTabs"));
// Wrap the components with the withAuthorization HOC to protect the routes

const ProtectedRoute = ({ element, rights, allowedRights }) => {
  //console.log(rights);
  const tempoToken = sessionStorage.getItem("token");
  // console.log(tempoToken);
  if (tempoToken === "undefined" || tempoToken === null) {
    return <Navigate to="/unauthorized" replace />;
  }

  const hasPermission = allowedRights.some((right) => rights.includes(right));
  return hasPermission ? element : <Navigate to="/unauthorized" replace />;
};
export default function Routing({ userRights, setUserRights }) {
  let elements = useRoutes([
    {
      path: "/",
      element: <Login setUserRights={setUserRights} />,
    },
    {
      path: "unauthorized",
      element: <Unauthorized />,
    },

    {
      path: "trtmnt",
      element: (
        <ProtectedRoute
          element={<Treatment />}
          rights={userRights}
          allowedRights={["Doctor"]}
        />
      ),
    },
    {
      path: "cri-crm",
      element: <Login setUserRights={setUserRights} />,
    },
    {
      path: "crm",
      element: <CrmCLT />,
    },
    {
      path: "ccare",
      element: <Ccare />,
    },

    {
      path: "phmcy",
      element: (
        <ProtectedRoute
          element={<Pharmacy />}
          rights={userRights}
          allowedRights={["Admin", "Reception", "Accounts", "Manager"]}
        />
      ),
    },
    {
      path: "reports",
      element: (
        <ProtectedRoute
          element={<CRMReports />}
          rights={userRights}
          allowedRights={["Admin", "Manager", "Reception"]}
        />
      ),
    },
    {
      path: "invoices",
      element: (
        <ProtectedRoute
          element={<SalesInvoices />}
          rights={userRights}
          allowedRights={["Admin", "Manager", "Reception", "Accounts"]}
        />
      ),
    },
    {
      path: "rtn-invoices",
      element: (
        <ProtectedRoute
          element={
            <Suspense
              fallback={<div>Component1 is loading, please wait...</div>}
            >
              <RtnSalesInvoices />
            </Suspense>
          }
          rights={userRights}
          allowedRights={["Admin", "Manager", "Reception", "Accounts"]}
        />
      ),
    },
    {
      path: "accounts",
      element: (
        <ProtectedRoute
          element={
            <Suspense
              fallback={<div>Component1 is loading, please wait...</div>}
            >
              <Accounts />
            </Suspense>
          }
          rights={userRights}
          allowedRights={["Admin", "Manager", "Reception", "Accounts"]}
        />
      ),
    },
    {
      path: "accounts/items-view",
      element: (
        <Suspense fallback={<div>Component1 is loading, please wait...</div>}>
          <ItemViceView />
        </Suspense>
      ),
    },
    {
      path: "billentry",
      element: (
        <ProtectedRoute
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BillEntry />
            </Suspense>
          }
          rights={userRights}
          allowedRights={["Admin", "Accounts", "Manager", "Reception"]}
        />
      ),
    },
    {
      path: "otbooking",
      element: (
        <ProtectedRoute
          element={<Booking />}
          rights={userRights}
          allowedRights={[
            "Admin",
            "Reception",
            "Doctor",
            "Manager",
            "Accounts",
          ]}
        />
      ),
    },
    {
      path: "customer_form",
      element: (
        <ProtectedRoute
          element={<CustomerForm />}
          rights={userRights}
          allowedRights={["Admin", "Reception", "Customer", "Manager"]}
        />
      ),
    },
    {
      path: "crm-clt",
      element: (
        <ProtectedRoute
          element={<CrmCLT />}
          rights={userRights}
          allowedRights={["Admin", "Reception", "Accounts", "Manager"]}
        />
      ),
    },
    {
      path: "phmcy-admin",
      element: (
        <ProtectedRoute
          element={<AdminPage />}
          rights={userRights}
          allowedRights={["Admin", "Accounts", "Manager", "Reception"]}
        />
      ),
      children: [
        { path: "", element: <StockManagementPage /> },
        { path: "purchase", element: <Purchase /> },
        {
          path: "rtn-purchase",
          element: (
            <Suspense
              fallback={<div>Component1 is loading, please wait...</div>}
            >
              <PurchaseReturns />
            </Suspense>
          ),
        },
        { path: "inventory", element: <InventoryForm /> },
        { path: "suppliers", element: <Suppliers /> },
        { path: "sku", element: <StockManagementPage /> },
        { path: "procedures", element: <ProceduresManagement /> },
        { path: "ip", element: <InPatient /> },
        { path: "consume", element: <Consumption /> },
        { path: "sales", element: <Reports /> },
        { path: "removed-items", element: <RemovedItems /> },
      ],
    },
    {
      //checking pupose to refresh inventory

      path: "phmcy-admin/inventory",
      element: (
        <ProtectedRoute
          element={<AdminPage />}
          rights={userRights}
          allowedRights={["Admin", "Accounts", "Manager", "Reception"]}
        />
      ),
      children: [{ path: "", element: <InventoryForm /> }],
    },
    {
      path: "lab-admin",
      element: (
        <ProtectedRoute
          element={<Lab />}
          rights={userRights}
          allowedRights={[
            "Admin",
            "Accounts",
            "Manager",
            "Reception",
            "LabAdmin",
            "Doctor",
          ]}
        />
      ),
      children: [
        { path: "", element: <LabCategory /> },
        { path: "lab", element: <LabManagement /> },
        { path: "lab-cat", element: <LabCategory /> },
        { path: "lab-report", element: <LabReportEntry /> },
        { path: "lab-results", element: <LabResultsList /> },
      ],
    },
    {
      path: "admin",
      element: (
        <ProtectedRoute
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Admin />
            </Suspense>
          }
          rights={userRights}
          allowedRights={[
            "Admin",
            "Accounts",
            "Manager",
            "Reception",
            "LabAdmin",
          ]}
        />
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <UserManagement />
            </Suspense>
          ),
        },
        {
          path: "user-management",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <UserManagement />
            </Suspense>
          ),
        },
        {
          path: "lab-cat",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <LabCategory />
            </Suspense>
          ),
        },
        {
          path: "lab-report",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <LabReportEntry />
            </Suspense>
          ),
        },
        {
          path: "lab-results",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <LabResultsList />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "que-sys", //queue-management"
      element: (
        <ProtectedRoute
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <QueueSystem />
            </Suspense>
          }
          rights={userRights}
          allowedRights={[
            "Admin",
            "Accounts",
            "Manager",
            "Reception",
            "LabAdmin",
          ]}
        />
      ),
    },
    {
      path: "queue-management", //queue-management"
      element: (
        <ProtectedRoute
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <QueueManagement />
            </Suspense>
          }
          rights={userRights}
          allowedRights={[
            "Admin",
            "Accounts",
            "Manager",
            "Reception",
            "LabAdmin",
          ]}
        />
      ),
    },
    {
      path: "bill-tabs", //queue-management"
      element: (
        <ProtectedRoute
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BillTabs />
            </Suspense>
          }
          rights={userRights}
          allowedRights={[
            "Admin",
            "Accounts",
            "Manager",
            "Reception",
            "LabAdmin",
          ]}
        />
      ),
    },
  ]);

  return elements;
}
